import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import Header2 from "../component/Header2";
import Footer from "../component/Footer";
import "./home.css";

import { useNavigate } from "react-router-dom";
// import ChatForm from "../component/LetsTalk";

const Homepage = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  const handleWhatsAppRedirect = () => {
    // Replace the 'phone_number' below with the actual phone number you want to message
    const phoneNumber = "9015937199";
    const message = encodeURIComponent("Hello, I want to get started!");

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  };
  const redirectToEmail = () => {
    const emailAddress = "pawankaushik60@gmail.com";
    const mailtoLink = `mailto:${emailAddress}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    // Initial check on mount
    handleResize();

    // Add event listener to check on resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const card = [
    {
      head: "Dedicated Servers",
      p1: "Servers with full root & IPMI access. No virtualization overhead.",
      p2: "Available in India : Starts @ ₹9999 /month",
      img: "./img/home1.svg",
      to: "/dedicated",
    },
    {
      head: "VPS (Virtual Private Server)",
      p1: "Servers with full root & IPMI access. No virtualization overhead.",
      p2: "Available in India : Starts @ ₹1399 /month",
      img: "./img/home1.svg",
      to: "/cloudvps",
    },
    {
      head: "Bare-Metal Servers",
      p1: "Servers with full root & IPMI access. No virtualization overhead.",
      p2: "Available in India : Starts @ ₹11999 /month",
      img: "./img/home1.svg",
      to: "/dedicated",
    },
    {
      head: "Cloud Servers",
      p1: "Lightning-fast servers with root access, SSD storage. Easy scalability.",
      p2: "Available in India : Starts @ ₹350/month",
      img: "./img/home2.svg",
      to: "/cloudvps",
    },
    {
      head: "Shared Hosting",
      p1: "Our shared hosting solution provides blazing fast SSD storage and unlimited email accounts to get your website online quickly.",
      p2: "Available in India : Starts @ ₹99/month",
      img: "./img/home3.svg",
      to: "/shared-hosting",
    },
    {
      head: "Reseller Hosting",
      p1: "Our reseller hosting service comes with SSD storage, backups and overselling option. Start your own web hosting business today.",
      p2: "Available in India : Starts @ ₹199/month",
      img: "./img/home4.svg",
      to: "/linux-reseller",
    },
    {
      head: "Business E-Mail Solutions",
      p1: "Perfect solution for any-sized business, including SMBs, enterprise organizations or web hosts and ISPs.",
      p2: "Available in India : Starts @ ₹49/month",
      img: "./img/home5.svg",
      to: "/",
    },
    {
      head: "Backup & Disaster Recovery",
      p1: "Ransomware protected cloud backup solution.",
      p2: "Available in India: Starts @ ₹499/month",
      img: "./img/home6.svg",
    },
  ];
  const card2 = [
    {
      img: "./img/crd1.avif",
      head: "24X7 support",
      p: `You can sit back and relax as our highly proficient support team is available for 24 x 7 x 365 to resolve your query and ensure that your servers are running top notch and delivering maximum out of it to your customers.`,
    },
    {
      img: "/img/slide2.png",
      head: "Free migration",
      p: `We will be privileged to migrate your first website for absolutely free.`,
    },
    {
      img: "/img/slide3.png",
      head: "Free SSL on 3 year subscription",
      p: `We offer you free and trusted encryption SSL certificate for 1 year * to improve your website security and SEO Presence which accomplishes all your HTTPS requirements.`,
    },
    {
      img: "/img/slide5.jpeg",
      head: "99.9% uptime",
      p: `99.9% Uptime We are firmly committed to delivering 99.9% uptime of your server system so You can serve your customers in a better way without any hassle.You can sit back and relax as our highly proficient support team is available for 24 x 7 x 365 to resolve your query and ensure that your servers are running top notch and delivering maximum out of it to your customers.`,
    },
    {
      img: "/img/slide4.jpeg",
      head: "30 Days Guarantee",
      p: `If you don't comfortable with our services then you are free to unsubscribe yourself within 30 days and you will be entitled to a money back guarantee* without any concern.`,
    },
    {
      img: "/img/slide6.png",
      head: "Scalable performance",
      p: `We offer such flexibility so that You can scale up or down your hosting at any moment according to your website or application traffic loads.`,
    },
    {
      img: "/img/slide7.png",
      head: "Gzip",
      p: `Our data compressing facility reduce the load on your server and improves the performance of your server and open your website or application rapidly.`,
    },
    {
      img: "/img/slide8.jpeg",
      head: "Manage Security      ",
      p: `We provide the extra layer of security to prevent your website or application from unwanted interventions. All our servers are protected by OS-level firewalls that filter out malicious traffic.`,
    },
    {
      img: "/img/slide9.png",
      head: "Manage PHP      ",
      p: `All our servers are php7.x compatible and PHP-FPM dramatically speeds up your website and improves the loading times of your PHP environment with much faster processes.`,
    },
  ];

  return (
    <>
      {isMobile ? <Header2 /> : <Header />}
      {/* <ChatForm /> */}
      <div className="home3 marginbotm">
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-sm-12">
              <h1 style={{ color: "rgb(230,57,63)" }}>
                Experts in Cloud Services
              </h1>
              <p
                style={{
                  fontSize: "25px",
                  lineHeight: "30px",
                  fontWeight: "400",
                }}
              >
                {/* <span style={{ color: "skyblue" }}>Fast</span> |{" "}
                <span style={{ color: "orange" }}>Easy</span> |{" "}
                <span style={{ color: "green" }}>Secure</span> */}
                Superfast Cloud Services
              </p>
              <ul>
                <li>5000+ customers across globally</li>
                <li>Fully Managed Servers</li>
                <li>Tailored Solutions</li>
                <li>24/7 Support - Chat / Call / E-Mail / Tickets</li>
                <li>5 locations world-wide</li>
              </ul>
              <button
                style={{
                  // border: "1px solid rgb(230,57,63)",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "16px",

                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "green",
                  border: "none",
                }}
                // onClick={() => {
                //   const hostingDiv = document.getElementById("start");
                //   if (hostingDiv) {
                //     hostingDiv.scrollIntoView({ behavior: "smooth" });
                //   }
                // }}
                onClick={() => {
                  navigate("/grow-with-us");
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <h1 className="headhome text-center" id="start">
        Enterprise Cloud Solution
      </h1>
      <div className="container">
        <div className="row">
          {card.map((e) => (
            <div className="col-md-6 col-sm-12 p-3 ">
              <div className="mapmain p-3">
                <h3 className="maph3">{e.head}</h3>
                <div className="mapdiv">
                  <div>
                    <p className="mapp">{e.p1}</p>
                    <p className="mapp">{e.p2}</p>
                  </div>
                  <img src={e.img} alt="img" />
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigate(e.to);
                    }}
                    style={{
                      // border: "1px solid rgb(230,57,63)",
                      borderRadius: "10px",
                      padding: "7px 20px",
                      fontSize: "16px",

                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: "green",
                      border: "none",
                    }}
                  >
                    Explore
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              style={{
                fontSize: "32px",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              Boost Your Business With SSTCLOUD
            </h1>
          </div>
        </div>
        <div className="row text-center">
          {card2.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12  p-3 d-flex justify-content-center">
                <div
                  style={{
                    boxShadow: "0px 0px 10px #1a1a1a",
                    // height: "470px",
                    // width: "300px",
                    padding: "20px",
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    style={{ height: "150px", width: "150px" }}
                    src={e.img}
                    alt="img"
                  />
                  <h3>{e.head}</h3>
                  <p>{e.p}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="bghome">
        <div className="container py-5">
          <div className="row ">
            <div className="col-md-6 col-sm-12"></div>
            <div className="col-md-6 col-sm-12 homeend">
              <h1>NEED PROFESSIONAL HELP?</h1>
              <p className="py-4">
                Our in-house, certified experts are always available to answer
                your questions, get you started, and grow your presence online.
                You can call, chat or email us any time!
              </p>
              <div className="d-flex justify-content-around">
                <button
                  style={{
                    borderRadius: "10px",
                    padding: "7px 20px",
                    fontSize: "16px",

                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "green",
                    border: "none",
                  }}
                  className="mx-3"
                  onClick={redirectToEmail}
                >
                  Email Us
                </button>
                <button
                  style={{
                    // border: "1px solid rgb(230,57,63)",
                    borderRadius: "10px",
                    padding: "7px 20px",
                    fontSize: "16px",

                    color: "white",
                    fontWeight: "bold",
                    backgroundColor: "green",
                    border: "none",
                  }}
                  className="mx-3"
                  onClick={handleWhatsAppRedirect}
                >
                  Chat With Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Homepage;
